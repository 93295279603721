import React from "react"

import Layout from "../components/Layout"

export default function About() {
  const data1 =
    "&#13&#10&#77&#114&#46&#32&#76&#97&#119&#114&#101&#110&#99&#101&#32&#80&#97&#99&#107"
  const data2 = "&#13&#10&#77&#114&#46&#32&#80&#97&#99&#107&#39&#115"
  const data3 = "&#77&#114&#46&#32&#80&#97&#99&#107"
  return (
    <Layout title="About Us">
      <div className="abouts segments-page default-container">
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Overview</h2>
                <div className="social-link"></div>
                <p>
                  Pack Trading Group is an international trading company. We
                  serve as a manufacturer's representative and exporter for the
                  health and beauty industries. We offer our clients the ability
                  to expand into new markets by attending foreign tradeshows and
                  finding foreign distributors. We offer hands-on management of
                  these distributors by meeting and interacting with them in
                  their home countries.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Our History</h2>
                <div className="social-link"></div>
                <p>
                  Ronald Blatteis founded <em>Pacific Trading Group</em> in 1999. Mr.
                  Blatteis' educational background includes a Bachelor of Arts
                  Degree in Industrial Psychology from the University of
                  California, Berkeley and a Masters of Business Administration
                  (MBA) in International Marketing also from the University of
                  California, Berkeley.
                </p>
                <p>
                  Mr. Blatteis' work background includes work as a Marketing
                  Representative for IBM Corporation followed by marketing
                  positions leading to the title of International Sales Manager
                  for Tencor Instruments. During his 18 year career with
                  KLA/Tencor Corporation he was founder and President of it's
                  subsidiary in Japan where he resided for 3 years. Mr. Blatteis
                  was one of the first employees of the company in it's start up
                  phase and was with the company when it achieved it's first $1M
                  sales year. Today, revenues for KLA-Tencor are in excess of
                  $1.7 billion (NASDAQ: KLAC).
                </p>
                <p>
                  Mr. Blatteis left KLA-Tencor in 1996 to become President of
                  Blue Mountain International, a corporation dedicated to the
                  distribution of medical products in Asia. In 1999, Blue
                  Mountain International was closed and Mr. Blatteis started
                  Pacific Trading Group to bring his partners and business
                  associates together to export and distribute products to Asia.{" "}
                </p>
                <p>
                  In 2019 Mr. Blatteis retired and Pacific Trading Group was
                  acquired by Pack Trading Group, Inc which is owned by
                  <span
                    dangerouslySetInnerHTML={{ __html: data1 }}
                  ></span>.{" "}
                  <span dangerouslySetInnerHTML={{ __html: data2 }}></span>{" "}
                  educational background includes a PhD in Physics and a
                  Master's degree in Mathematics.{" "}
                  <span dangerouslySetInnerHTML={{ __html: data3 }}></span> has
                  extensive experience working internationally and has
                  previously founded a digital marketing agency. Pacific Trading
                  Group remains a vibrant and dynamic company under the
                  stewardship of{"  "}
                  <span dangerouslySetInnerHTML={{ __html: data3 }}></span>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
